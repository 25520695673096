import Vue from "vue"
import Axios from "axios"
import store from '@/store'
import router from "@/router"
window.axiosCancel = false;
const request = {
	/**
	 * axiosRequest 封装
	 * @param url         [string]    ：接口地址
	 * @param data        [object]    ：接口参数（JSON格式），此参数不传递时默认为空（{}）
	 * @param success     [callback]  ：成功回调函数
	 * @param error       [callback]  ：失败回调
	 * @param showLoading     [bool]      ：false true 是否开启load加载状态  默认开启 true
	 * return null
	 */
	axiosRequest: (
		url,
		showLoading,
		data,
		success,
		error
	) => {
		// section是否加载loading
		if (showLoading) {
			store.commit('SHOWLOADING');
		}
		let config = {
			data: data
		};
		let session = window.sessionStorage;
		let contentType = 'application/json';

		//在请求头加入token
		Axios.defaults.headers.common['token'] = session.getItem('token') ? session.getItem('token') : '';
		Axios.request(Object.assign(config, {
				url: url,
				method: 'post',
				timeout: 600000,
				headers: {
					'Content-Type': contentType
				}
			}))
			.then(function (response) {
				if (showLoading) {
					store.commit('HIDELOADING');
				}
				// 拦截多次token失效提示弹出框
				if(window.axiosCancel) {
					return
				}
				if(response.data.code == 4) {
					request.backLogin();
					Vue.prototype.$message({
						message: response.data.msg,
						type: 'error'
					});
					window.axiosCancel = true;
					return
				}
				//ajax成功，但接口未必返回正确
				if (response.status === 200) {
					//token失效，重新登陆
					if (response.data.code === '500') {
						// request.backLogin();
						Vue.prototype.$message({
							message: response.data.msg,
							type: 'error'
						});
					} else {
						if (typeof success == "function") success(response.data);
					}
				} else {
					//错误回调
					if (typeof error == "function") error(response.data);
					// request.backLogin();
				}
			})
			.catch(function (error) {
				if (showLoading) {
					store.commit('HIDELOADING');
				}
			});
	},

	/**
	 * post 执行 api接口
	 * @param api_url   [string]    ：接口地址 如：api.login
	 * @param data      [object]    ：接口参数（JSON格式），此参数不传递时默认为空（{}）
	 * @param success   [callback]  ：成功回调函数
	 * @param error     [callback]  ：失败回调
	 * @param showLoading     [bool]      ：false true 是否开启load加载状态  默认开启 true
	 * return null
	 */
	post: (
		api_url = "",
		showLoading = false,
		data = {},
		success = null,
		error = null
	) => {
		// 调用axios
		request.axiosRequest(
			Vue.prototype.$api[api_url].url,
			showLoading,
			data,
			function (r) {
				if (typeof success == "function") success(r);
			},
			function (r) {
				if (typeof error == "function") error(r);
			}
		);
	},

	axiosGetRequest: (
		url,
		showLoading,
		data,
		success,
		error
	) => {
		// // 判断缓存是否被清除
		// if(!window.sessionStorage.getItem('companyCode') && router.app._route.name != 'Login') {
		// 	Vue.prototype.$message({
		// 		message: i18n.t('common.key_cache_cleared'),
		// 		type: 'error'
		// 	});
		// 	request.backLogin();
		// 	return
		// }
		// section是否加载loading
		if (showLoading) {
			store.commit('SHOWLOADING');
		}
		let config = {
			params: data
		};
		let session = window.sessionStorage;
		let contentType = 'application/json';
		//在请求头加入token
		Axios.defaults.headers.common['token'] = session.getItem('token') ? session.getItem('token') : '';
		Axios.request(Object.assign(config, {
				url: url,
				method: 'get',
				timeout: 600000,
				// headers: {
				// 	'Content-Type': contentType
				// }
			}))
			.then(function (response) {
				if (showLoading) {
					store.commit('HIDELOADING');
				}
				// 拦截多次token失效提示弹出框
				if(window.axiosCancel) {
					return
				}
				if(response.data.code == 4) {
					request.backLogin();
					Vue.prototype.$message({
						message: response.data.msg,
						type: 'error'
					});
					window.axiosCancel = true;
					return
				}
				//ajax成功，但接口未必返回正确
				if (response.status === 200) {
					//token失效，重新登陆
					if (response.data.code === '500') {
						// request.backLogin();
						Vue.prototype.$message({
							message: response.data.msg,
							type: 'error'
						});
					} else {
						if (typeof success == "function") success(response.data);
					}
				} else {
					//错误回调
					if (typeof error == "function") error(response.data);
					// request.backLogin();
				}
			})
			.catch(function (error) {
				if (showLoading) {
					store.commit('HIDELOADING');
				}
			}
		);
	},
	/**
	 * post 执行 api接口
	 * @param api_url   [string]    ：接口地址 如：api.login
	 * @param data      [object]    ：接口参数（JSON格式），此参数不传递时默认为空（{}）
	 * @param success   [callback]  ：成功回调函数
	 * @param error     [callback]  ：失败回调
	 * @param showLoading     [bool]      ：false true 是否开启load加载状态  默认开启 true
	 * return null
	 */
	get: (
		api_url = "",
		showLoading = false,
		data = {},
		success = null,
		error = null
	) => {
		// 调用axios
		request.axiosGetRequest(
			Vue.prototype.$api[api_url].url,
			showLoading,
			data,
			function (r) {
				if (typeof success == "function") success(r);
			},
			function (r) {
				if (typeof error == "function") error(r);
			}
		);
	},
	axiosGetDownloadRequest: (
		url,
		showLoading,
		data,
		success,
		error
	) => {
		if (showLoading) {
			store.commit('SHOWLOADING');
		}
		let config = {
			params: data
		};
		let session = window.sessionStorage;
		//在请求头加入token
		Axios.defaults.headers.common['token'] = session.getItem('token') ? session.getItem('token') : '';
		Axios.request(Object.assign(config, {
				url: url,
				method: 'get',
				timeout: 600000,
				responseType: 'blob',
			}))
			.then(function (response) {
				if (showLoading) {
					store.commit('HIDELOADING');
				}
				// 拦截多次token失效提示弹出框
				if(window.axiosCancel) {
					return
				}
				if(response.data.code == 4) {
					request.backLogin();
					Vue.prototype.$message({
						message: response.data.msg,
						type: 'error'
					});
					window.axiosCancel = true;
					return
				}
				//ajax成功，但接口未必返回正确
				if (response.status === 200) {
					//token失效，重新登陆
					if (response.data.code === '500') {
						// request.backLogin();
						Vue.prototype.$message({
							message: response.data.msg,
							type: 'error'
						});
					} else {
						if (typeof success == "function") success(response.data);
					}
				} else {
					//错误回调
					if (typeof error == "function") error(response.data);
					// request.backLogin();
				}
			})
			.catch(function (error) {
				if (showLoading) {
					store.commit('HIDELOADING');
				}
			});
	},
	download: (
		api_url = "",
		showLoading = false,
		data = {},
		success = null,
		error = null
	) => {
		// 调用axios
		request.axiosGetDownloadRequest(
			Vue.prototype.$api[api_url].url,
			showLoading,
			data,
			function (r) {
				if (typeof success == "function") success(r);
			},
			function (r) {
				if (typeof error == "function") error(r);
			}
		);
	},
	/**
	 * 退去首页
	 */
	backLogin() {
		window.sessionStorage.clear();
		store.commit('HIDELOADING');
		router.push('/login');
	},
	/**
	 * 初始导入
	 * formdata:{
	 * 		file文件流
	 * 		teamId分组id
	 * 		type类型
	 * 	}
	 * api_url:接口地址
	*/
	initUploadExcel(formdata,api_url,show){
		if (show) {
			store.commit('SHOWLOADING');
		}
		let config = {headers: {'Content-Type': 'multipart/form-data'}};
		return Axios.post(
			Vue.prototype.$api[api_url].url,
			formdata,
			config
		).then(res => {
			if (show) {
				store.commit('HIDELOADING');
			}
			// 拦截多次token失效提示弹出框
			if(window.axiosCancel) {
				return
			}
			if(res.data.code == 4) {
				request.backLogin();
				Vue.prototype.$message({
					message: response.data.msg,
					type: 'error'
				});
				window.axiosCancel = true;
				return
			}
			if(res.data.code == '0'){

			}else{
				Vue.prototype.$message({
					message: res.data.msg,
					type: 'error'
				})
			}
			return res.data
		})
	},
}

export default request;
