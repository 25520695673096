import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		pageLoading: false,
	},
	mutations: {
		SHOWLOADING: (state) => {
			state.pageLoading = true;
		},
		HIDELOADING: (state) => {
			state.pageLoading = false;
		}
	},
	actions: {

	},
	modules: {

	}
})
