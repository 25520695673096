<template>
	<div class="app-header">
		<img class="logo" src="@/assets/images/logo.png" alt="">
		<div class="hearder-right">
			<p class="user-info">
				<img class="touxiang" src="@/assets/images/touxiang.png" alt="" @click="handleUserInfo">
				<span @click="handleUserInfo" class="username">{{username}}</span>
				<img class="back-btn" src="@/assets/images/zhuxiao.png" alt="" @click="back">
			</p>
			<el-menu
				v-if="emailFlag"
				mode="horizontal"
				router
				:default-active="defaultActivePath">
				<div v-for="(item,index) in menuList" :key="index" class="el-menu-item-div">
					<el-menu-item  :index="item.path" :disabled="!perms.includes(item.perm)">
						<span slot="title" class="title-box">
							<span class="title">{{item.name}}</span>
							<span class="border-bottom"></span>
						</span>
					</el-menu-item>
				</div>
			</el-menu>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			menuList: [
				{
					name: '首页',
					path: '/home',
					perm: 'home'
				},
				{
					name: '公告栏',
					path: '/bulletin',
					perm: 'notice'
				},
				{
					name: '图书馆',
					path: '/library',
					perm: 'library'
				},
				{
					name: '通信箱',
					path: '/mailbox',
					perm: 'subject'
				},
				{
					name: '培训管理',
					path: '/trainingManagement',
					perm: 'train'
				},
				{
					name: '系统管理',
					path: '/systemManagement',
					perm: 'sys'
				}
			],
			defaultActivePath: '',
			username: '',
			// 没有邮箱隐藏菜单
			emailFlag: true
		}
	},
	created () {
		// 设置用户名
		let name = JSON.parse(window.sessionStorage.getItem('userInfo')).name;
		this.username = name ? name : '无用户名';
		// 处理路由重复点击报错问题
		this.handleRepeatRoute();
	},
	mounted() {

	},
	watch:{
		$route(from,to) {
			this.handleRepeatRoute();
		}
	},
	methods: {
		// 修改用户信息
		handleUserInfo() {
			if(this.emailFlag) {
				this.$router.push({
					name: 'editUserInfo'
				})
			}
		},
		// 退出登录
		back() {
			this.$confirm('是否退出登录?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				window.sessionStorage.setItem('token','');
				window.sessionStorage.setItem('perms','');
				this.$router.push({
					name: 'login'
				})
			}).catch(() => {

			});
		},
		// 处理路由重复点击报错问题
		handleRepeatRoute() {
			// 没有邮箱隐藏菜单
			if(this.$route.name == 'editUserEmail') {
				this.emailFlag = false;
			}
			this.defaultActivePath = this.$route.path;
			let inMenu = false;
			for (const path of this.menuList) {
				if (this.defaultActivePath.indexOf(path.path) === 0) {
					this.defaultActivePath = path.path
					inMenu = true
					break
				}
			}
			if (!inMenu) {
				this.defaultActivePath = '';
			}
			let routeNameList = ['examLearn','examBefore','exameIn','examAfter'];
			if(routeNameList.includes(this.$route.name)) {
				this.defaultActivePath = '/trainingManagement';
			}
			let techNameList = ['technique', 'video', 'videoAddEdit', 'videoDetail']
			if (techNameList.includes(this.$route.name)) {
				this.defaultActivePath = '/home'
			}
		},
		// 吸顶效果
		handleScroll() {
			let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
		}
	}
}
</script>
<style lang="scss" scoped>
.app-header{
	position: fixed;
	top: 0;
	z-index: 111;
	width: 100%;
	height: 60px;
	// background: linear-gradient(85deg, #1296FB, #2188FD);
	background-color: #fff;
	box-shadow: 0px 1px 5px 1px rgba(79, 79, 79, 0.17);
	.logo{
		position: absolute;
		width: 140px;
		left: 10%;
		top: 10px;
	}
	.hearder-right{
		width: 800px;
		height: 60px;
		position: absolute;
		top: 0;
		right: 10%;
		line-height: 60px;
	}
	.el-menu{
		background: transparent;
		float: right;
		// margin-top: 10px;
		.el-menu-item:hover,
		.el-menu-item:focus {
			background-color: transparent;
		}
	}
	.el-menu.el-menu--horizontal{
		border: none;
		.el-menu-item-div{
			display: inline-block;
		}
		.el-menu-item{
			width: 96px;
			text-align: center;
		}
		.title-box{
			display: block;
			position: relative;
			color: #333;
			.border-bottom{
				display: none;
			}
		}
	}
	.el-menu--horizontal .el-menu-item.is-active{
		border: none;
		border-color: transparent;
		font-size: 17px;
		font-weight: bold;
		color: #000;
		.border-bottom{
			display: block;
			width: 32px;
			height: 4px;
			background-color: rgba(17, 127, 252, 1);
			position: absolute;
			bottom: -1px;
			left: 0;
			margin-left: 13px;
		}
	}
	.user-info{
		float: right;
		.touxiang{
			width: 38px;
			vertical-align: middle;
			margin-right: 10px;
			cursor: pointer;
		}
		.back-btn{
			width: 20px;
			margin-left: 10px;
			vertical-align: middle;
			cursor: pointer;
		}
		span{
			color: #333;
			font-size: 14px;
			cursor: pointer;
		}
	}
	.username{
		display: inline-block;
		max-width: 62px;
		height: 20px;
		line-height: 20px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		vertical-align: middle;
	}
}
// ::v-deep.el-menu {
    // background-color: transparent;

// }
</style>
