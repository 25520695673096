<template>
	<section :style="height">
		<transition name="fade-transform" mode="out-in">
			<router-view/>
		</transition>
		<p class="banquan">版权所有 © 2021理光（中国）投资有限公司</p>
		<p class="beian">备案编号：沪ICP备08111514号</p>
	</section>
</template>
<script>
export default {
	data() {
		return {
			height: ''
		}
	},
	computed: {
        key() {
            return this.$route.path
        },
	},
	created() {
		this.height = 'min-height:' + (window.innerHeight - 61) + 'px;'
	}
}
</script>
<style lang="scss" scoped>
section{
	width: 80%;
	margin: 60px auto 0;
	box-sizing: border-box;
    padding-top: 1px;
	.banquan{
		margin-top: 20px;
		text-align: center;
		color: #A8A8A8;
		font-size: 14px;
	}
	.beian{
		padding-bottom: 20px;
		text-align: center;
		color: #0067FF;
		font-size: 14px;
	}
}
</style>
