import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(Element);

//调用全局方法+api
import request from "@/utils/axios"
Vue.prototype.$request = request
import api from "@/api"
Vue.prototype.$api  = api
import moment from 'moment'
Vue.prototype.$moment = moment

import './assets/scss/index.scss' //全局修改mint-UI样式
import './assets/scss/common.scss' //全局通用样式

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
